export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const camelCaseToWords = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return capitalizeFirstLetter(result);
};

export const snakeCaseToWords = (text: string) => {
  return capitalizeFirstLetter(text.replaceAll('_', ' '));
};
