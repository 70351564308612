'use client';

import { MenuItemType, MenuNameType } from '@/config-schema/navigation';
import { useMemo } from 'react';
import useApplicationConfig from './use-application-config';
import { useNavigationMenuData } from './use-navigation-menu-data';

export const useHeaderLinks = () => {
  const navigationConfig = useApplicationConfig()?.components?.navigation;
  const { defaultHeaderLinkMap, routes } = useNavigationMenuData();

  return useMemo(() => {
    const enabledFeatureMap = new Map<MenuNameType, MenuItemType>();

    for (const route of routes) {
      if (typeof route === 'string') {
        const value = defaultHeaderLinkMap.get(route);
        if (value) {
          enabledFeatureMap.set(route, value);
        }
      }
    }

    // mixed with navigation config and sorted based on the ui settings
    const headerLinks: MenuItemType[] = [];
    if (navigationConfig?.orderedMenuItems) {
      const { orderedMenuItems } = navigationConfig;
      orderedMenuItems.map((configurableItem) => {
        if (enabledFeatureMap.has(configurableItem.name)) {
          const mappedMenuItem = enabledFeatureMap.get(configurableItem.name)!;
          if ('iconVisible' in configurableItem) {
            headerLinks.push({
              ...mappedMenuItem,
              iconVisible: configurableItem.iconVisible!,
            });
          } else {
            headerLinks.push({ ...mappedMenuItem });
          }
        }
      });
    } else {
      enabledFeatureMap.forEach((value) => {
        if (['earn', 'redeem'].includes(value.groupType)) {
          headerLinks.push(value);
        }
      });
    }

    const earnHeaderLinks: MenuItemType[] = headerLinks.filter(
      (item) => item.groupType === 'earn',
    );

    const redeemHeaderLinks: MenuItemType[] = headerLinks.filter(
      (item) => item.groupType === 'redeem',
    );

    let accountHeaderLinks: MenuItemType[] = [];
    enabledFeatureMap.forEach((value) => {
      if (value.groupType === 'myAccount') {
        accountHeaderLinks.push(value);
      }
    });

    let needHelpLinks: MenuItemType[] = [];
    enabledFeatureMap.forEach((value) => {
      if (value.groupType === 'needHelp') {
        needHelpLinks.push(value);
      }
    });

    let homepageLink: MenuItemType | undefined;
    enabledFeatureMap.forEach((value) => {
      if (value.groupType === 'home') {
        homepageLink = value;
      }
    });
    return {
      headerLinks,
      earnHeaderLinks,
      redeemHeaderLinks,
      accountHeaderLinks,
      needHelpLinks,
      homepageLink,
    };
  }, [defaultHeaderLinkMap, navigationConfig, routes]);
};
