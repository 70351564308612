import { useCurrency } from '../bootstrap/bootstrap-hooks';

export const useTenantCurrency = () => {
  const currency = useCurrency();
  return currency?.code ?? 'USD';
};

export const useTenantCurrencySymbol = () => {
  const currency = useCurrency();
  return currency?.symbol ?? '$';
};
