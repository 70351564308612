'use client';

import { captureException, isInitialized, SeverityLevel } from '@sentry/nextjs';
import { WontFix } from 'global';
import { useState } from 'react';

const sentryLogger: Record<SeverityLevel, (err: WontFix) => void> = {
  error: (err, ...sentryConfig) =>
    captureException(err, { level: 'error', ...sentryConfig }),
  fatal: (err, ...sentryConfig) =>
    captureException(err, { level: 'fatal', ...sentryConfig }),
  warning: (err, ...sentryConfig) =>
    captureException(err, { level: 'warning', ...sentryConfig }),
  info: (err, ...sentryConfig) =>
    captureException(err, { level: 'info', ...sentryConfig }),
  log: (err, ...sentryConfig) =>
    captureException(err, { level: 'log', ...sentryConfig }),
  debug: (err, ...sentryConfig) =>
    captureException(err, { level: 'debug', ...sentryConfig }),
};

const localSentryLogger: Record<SeverityLevel, WontFix> = {
  error: console.error,
  fatal: console.error,
  warning: console.warn,
  info: console.info,
  log: console.log,
  debug: console.debug,
};

export function useSentryMonitoring() {
  const [logger] = useState(isInitialized() ? sentryLogger : localSentryLogger);

  return { sentryLog: logger };
}
