import { ComponentProps, CSSProperties } from 'react';
import { Button } from 'react-aria-components';

import { useCurrencyList } from '@/hooks/currency-selector/use-currency-list';
import { cn } from '@/utils/tailwind';

import { CurrencySelectorPlaceholder } from './currency-selector-placeholder';

interface Props extends ComponentProps<typeof Button> {
  currency: ReturnType<typeof useCurrencyList>[number];
  hasDropdownIcon?: boolean;
}
export const CurrencySelectorTrigger = (props: Props) => {
  const { currency, isDisabled, hasDropdownIcon, ...restProps } = props;

  return (
    <Button
      isDisabled={isDisabled}
      style={
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          '--transition': 'background',
        } as CSSProperties
      }
      className={cn(
        'flex w-fit items-center gap-x-2 rounded p-1 outline-none',
        'transition-[var(--transition)] hover:bg-neutral-900 hover:bg-opacity-5',
        'data-[pressed=true]:bg-neutral-900 data-[pressed=true]:bg-opacity-10',
        'data-[disabled=true]:!bg-transparent data-[disabled=true]:opacity-50',
        'data-[focus-visible=true]:shadow-focus',
      )}
      {...restProps}
    >
      <CurrencySelectorPlaceholder
        currency={currency}
        hasDropdownIcon={hasDropdownIcon}
      />
    </Button>
  );
};
