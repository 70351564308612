import { MenuNameType } from '@/config-schema/navigation';
import { useCallback } from 'react';
import { useTrackAmplitudeEvent } from '../use-track-amplitude-event';

export const useTrackTravelNavigation = () => {
  const trackAmplitudeEvent = useTrackAmplitudeEvent();
  return useCallback(
    (itemType: MenuNameType, source: 'top_nav' | 'quick_link' | 'footer') => {
      if (itemType !== 'travel') {
        return;
      }
      trackAmplitudeEvent('Travel Click', {
        source,
      });
    },
    [trackAmplitudeEvent],
  );
};
