import { useQuery } from '@tanstack/react-query';

import { useHermes } from '@/auth/use-hermes';
import { defaultPageSize } from '@/design-system-components/pagination/pagination-config';
import { queryKeys } from '@/queries/query-factory';
import {
  cashbackLoyaltyProgramResponseSchema,
  cryptoLoyaltyProgramResponseSchema,
  frequentTravelerLoyaltyProgramResponseSchema,
  LoyaltyProgramType,
  loyaltyProgramTypeSchema,
  sustainabilityLoyaltyProgramResponseSchema,
  VoucherLoyaltyProgramResponseSchema,
} from '@/schema/loyalty-program.schema';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { useAuthFetchKy } from '../fetch/use-auth-fetch-ky';

type UseLoyaltyProgramsParams = {
  loyaltyProgramType: LoyaltyProgramType;
  sortBy?: string;
  search?: string;
  pageSize?: number;
  pageNumber?: number;
  enabled?: boolean;
};
export default function useLoyaltyPrograms(params: UseLoyaltyProgramsParams) {
  let {
    loyaltyProgramType,
    sortBy = '',
    search = '',
    pageSize = defaultPageSize,
    pageNumber = 1,
    enabled = true,
  } = params;

  const baseUrl = getClientUrl();
  const fetch = useAuthFetchKy;
  const hermes = useHermes();
  const headers = new Headers();

  headers.set('X-Response-Format', 'custom');

  let queryParams = '';

  switch (loyaltyProgramType) {
    case loyaltyProgramTypeSchema.enum.sustainability: {
      queryParams = '?filter[category][$eq]=sustainability';
      break;
    }
    case loyaltyProgramTypeSchema.enum.crypto: {
      queryParams = '?filter[category][$eq]=crypto';
      break;
    }
    case loyaltyProgramTypeSchema.enum.points_transfer: {
      queryParams =
        sortBy === 'personalization'
          ? '?'
          : '?filter[category][$eq]=hotel,airline';
      break;
    }
    case loyaltyProgramTypeSchema.enum.cashback: {
      queryParams =
        '?filter[category][$eq]=cashback_to_platform,cashback_to_tenant';
      break;
    }
    case loyaltyProgramTypeSchema.enum.voucher: {
      queryParams =
        '?filter[category][$eq]=voucher&filter[transferConnectIdentifier][$eq]=UBER';
      break;
    }
  }

  if (sortBy !== 'default') {
    queryParams += `&sort=${sortBy}`;
  }
  queryParams += `&page[number]=${pageNumber.toString()}`;
  queryParams += `&page[size]=${pageSize.toString()}`;

  if (search !== '') {
    queryParams += `&search[name]=${search}`;
  }

  let url = '';
  url =
    loyaltyProgramType === 'cashback'
      ? `${baseUrl}/api/products/${loyaltyProgramType}${queryParams}`
      : `${baseUrl}/api/products/points_transfer${queryParams}`;

  const { data, isFetched, isLoading, error, isError, refetch } = useQuery({
    ...queryKeys.products.list(loyaltyProgramType, queryParams),
    enabled: enabled && hermes.loggedIn,
    queryFn: async () => {
      const data = await fetch
        .get(url, {
          headers,
        })
        .json();

      switch (loyaltyProgramType) {
        case loyaltyProgramTypeSchema.enum.points_transfer: {
          return validateSchema({
            schemaName: 'points-transfer-items-schema',
            data: data,
            zodSchema: frequentTravelerLoyaltyProgramResponseSchema,
          });
        }
        case loyaltyProgramTypeSchema.enum.cashback: {
          return validateSchema({
            data,
            schemaName: 'cashback-items-schema',
            zodSchema: cashbackLoyaltyProgramResponseSchema,
          });
        }
        case loyaltyProgramTypeSchema.enum.crypto: {
          return validateSchema({
            schemaName: 'crypto-items-schema',
            data: data,
            zodSchema: cryptoLoyaltyProgramResponseSchema,
          });
        }
        case loyaltyProgramTypeSchema.enum.sustainability: {
          return validateSchema({
            schemaName: 'sustainability-items-schema',
            data: data,
            zodSchema: sustainabilityLoyaltyProgramResponseSchema,
          });
        }
        case loyaltyProgramTypeSchema.enum.voucher: {
          return validateSchema({
            schemaName: 'voucher-items-schema',
            data: data,
            zodSchema: VoucherLoyaltyProgramResponseSchema,
          });
        }

        default: {
          return {
            data: [],
            meta: { count: 0 },
          };
        }
      }
    },
    onError: () => {
      // todo
      console.info('todo: log to sentry');
    },

    staleTime: 1000 * 60 * 5,
  });

  return {
    isFetched,
    isLoading,
    isError,
    error,
    refetch,
    loyaltyPrograms: data || {
      data: [],
      meta: { count: 0 },
    },
  };
}
