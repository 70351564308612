import { useTranslations } from 'next-intl';
import { useCallback } from 'react';
import { useNumberFormatter } from 'react-aria';

import { useLoyaltyCurrency } from '@/hooks/bootstrap/bootstrap-hooks';
import { useFlagsReady } from '../flags-ready/use-flags-ready';

export function usePointsFormatter() {
  const t = useTranslations('usePointsFormatter');
  const loyaltyCurrency = useLoyaltyCurrency();
  const isM2020 = useFlagsReady('m2020_tenants');
  const numberFormatter = useNumberFormatter(
    isM2020 ? { maximumFractionDigits: 0 } : undefined,
  );

  const pointsText = t('points');

  return useCallback(
    (points: number | undefined, currency?: string) => {
      return points == null
        ? ''
        : `${numberFormatter.format(points)} ${
            currency || loyaltyCurrency?.name || pointsText
          }`;
    },
    [numberFormatter, loyaltyCurrency, pointsText],
  );
}
