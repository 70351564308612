export const defaultCurrency = 'USD' as const;

export const defaultCurrencySymbol = '$' as const;

export const currencies = {
  /**
   * NOTE(SonTruongAscenda):
   * Below are the official currency list. Reference:
   * https://kaligo.atlassian.net/browse/RPD-365
   */
  [defaultCurrency]: defaultCurrencySymbol,
  SGD: 'S$',
  AUD: 'A$',
  HKD: 'HK$',
  MYR: 'RM',
  BRL: 'R$',
  EUR: '€',
  IDR: 'Rp',
  /**
   * NOTE(SonTruongAscenda):
   * Based on the Sentry logger, we can see and add missing currencies for sale or demo tenants below.
   * https://ascenda.sentry.io/issues/6000442985/events/?alert_rule_id=15228051&alert_type=issue&environment=production&notification_uuid=c53bcefb-a503-4338-a06d-f6c2f6db1e32&project=4507248896573440&referrer=slack
   *
   * More context can be found in this thread:
   * https://kaligo.slack.com/archives/C076E813T7Y/p1729222547888229
   */
  INR: '₹',
  GBP: '£',
  SAR: 'ر.س',
  AFN: '؋',
} as const;
