export const flightRoutes = {
  home: '/travel/flights',
  results: '/travel/flights/results',
  details: '/travel/flights/details',
  review: '/travel/flights/review',
  checkout: '/travel/flights/checkout',
  checkoutDetails: (id: string) => `/travel/flights/checkout/${id}/details`,
  checkoutReview: (id: string) => `/travel/flights/checkout/${id}/review`,
  checkoutPayment: (id: string) => `/travel/flights/checkout/${id}/payment`,
  checkoutConfirmation: (id: string) =>
    `/travel/flights/checkout/${id}/confirmation`,
  scheduleChangeReview: (id: string) =>
    `/travel/flights/schedule-change/${id}/review`,
  scheduleChangeConfirmation: (id: string) =>
    `/travel/flights/schedule-change/${id}/confirmation`,
};
