import { ComponentProps, CSSProperties } from 'react';
import { ListBoxItem } from 'react-aria-components';

import { useCurrencyList } from '@/hooks/currency-selector/use-currency-list';
import { cn } from '@/utils/tailwind';

import { CurrencySelectorPlaceholder } from './currency-selector-placeholder';

interface Props extends ComponentProps<typeof ListBoxItem> {
  currency: ReturnType<typeof useCurrencyList>[number];
}
export const CurrencySelectorItem = (props: Props) => {
  const { currency, ...restProps } = props;

  return (
    <ListBoxItem
      id={currency.value}
      style={
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          '--currency-selector-item-transition': 'background',
        } as CSSProperties
      }
      className={cn(
        'flex items-center gap-x-2 px-4 py-2 outline-none',
        'hover:cursor-pointer hover:bg-primary-200',
        'focus:bg-primary-200 focus:outline-none',
        'transition-[var(--currency-selector-item-transition)]',
        'data-[selected=true]:font-bold',
      )}
      aria-label={currency.label}
      {...restProps}
    >
      <CurrencySelectorPlaceholder currency={currency} />
    </ListBoxItem>
  );
};
