import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { currencies } from '@/components/currency-selector/constants';
import { Currency } from '@/components/currency-selector/types';

export const useCurrencyList = () => {
  const t = useTranslations('currencySelector.label');

  const currencyList = useMemo(() => {
    return Object.entries(currencies).map(([currency, symbol]) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const value = currency as Currency;
      return { value, label: t(value), symbol };
    });
  }, [t]);

  return currencyList;
};
