import {
  defaultAlt,
  defaultHeight,
  defaultSrc,
  defaultWidth,
  spinnerImageDisplayName,
} from './constants';
import type { SpinnerImageProps } from './types';

export function SpinnerImage({
  alt,
  height,
  src,
  width,
  ...props
}: SpinnerImageProps) {
  const altWithFallback = alt || defaultAlt;
  const heightWillFallback = height ?? defaultHeight;
  const srcWithFallback = src || defaultSrc;
  const widthWithFallback = width ?? defaultWidth;

  return (
    <img
      {...props}
      alt={altWithFallback}
      decoding="async"
      height={heightWillFallback}
      loading="lazy"
      role="img"
      src={srcWithFallback}
      width={widthWithFallback}
    />
  );
}

SpinnerImage.displayName = spinnerImageDisplayName;
