'use client';

import { useLocalStorageState } from 'ahooks';
import { useEffect, useState } from 'react';
import {
  TENANT_HUB_MESSAGE_TYPE,
  TenantHubConfig,
  useIsTenantHub,
} from './use-is-tenanthub';

export function useTenantHubConfig() {
  const isTenantHubConfig = useIsTenantHub();
  const [localStorageState] = useLocalStorageState<TenantHubConfig>(
    TENANT_HUB_MESSAGE_TYPE,
  );
  const [tenantHubConfig, setTenantHubConfig] = useState<TenantHubConfig>();

  useEffect(() => {
    setTenantHubConfig(localStorageState);
  }, [localStorageState]);

  return isTenantHubConfig ? tenantHubConfig : ({} satisfies TenantHubConfig);
}
