import { MenuNameType } from '@/config-schema/navigation';
import { useCallback } from 'react';
import { useTrackAmplitudeEvent } from '../use-track-amplitude-event';

const trackableItemTypes = new Set([
  'cashback',
  'crypto',
  'giftCard',
  'pointsTransfer',
]);

const itemTypeToProductDetailsMap = new Map<
  MenuNameType,
  {
    name: string;
    type: string;
  }
>([
  [
    'cashback',
    {
      name: 'Cashback',
      type: 'cashback',
    },
  ],
  [
    'crypto',
    {
      name: 'Crypto',
      type: 'crypto',
    },
  ],
  [
    'giftCard',
    {
      name: 'Gift Card',
      type: 'gift_card',
    },
  ],
  [
    'pointsTransfer',
    {
      name: 'Points Transfer',
      type: 'points_transfer',
    },
  ],
]);

export const useTrackProductNavigation = () => {
  const trackAmplitudeEvent = useTrackAmplitudeEvent();
  return useCallback(
    (itemType: MenuNameType, source: 'top_nav' | 'quick_link' | 'footer') => {
      if (!trackableItemTypes.has(itemType)) {
        return;
      }
      const productDetails = itemTypeToProductDetailsMap.get(itemType);
      if (!productDetails) {
        return;
      }
      trackAmplitudeEvent(`${productDetails.name} Redeemed`, {
        redemptionStep: `${productDetails.type}_click`,
        source,
      });
    },
    [trackAmplitudeEvent],
  );
};
