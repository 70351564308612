import { useTranslations } from 'next-intl';
import { useCallback, useMemo } from 'react';
import { DialogTrigger, ListBox } from 'react-aria-components';

import Drawer, {
  DrawerBody,
  DrawerHeader,
} from '@/design-system-components/drawer/drawer';
import DsSelect from '@/design-system-components/ds-select/select';
import { useCurrencyList } from '@/hooks/currency-selector/use-currency-list';
import { useCurrencyMutation } from '@/hooks/currency-selector/use-currency-mutation';
import { useSentryMonitoring } from '@/hooks/monitoring/use-sentry-monitoring';
import {
  useTenantCurrency,
  useTenantCurrencySymbol,
} from '@/hooks/travel/use-tenant-currency';
import { useIsPublicPage } from '@/utils/page-url';
import { useIsMobile } from '@/utils/use-is-mobile';

import { CurrencySelectorItem } from './currency-selector-item';
import { CurrencySelectorTrigger } from './currency-selector-trigger';
import { Currency } from './types';

/**
 * NOTE(SonTruongAscenda):
 * For now we just show the currency and don't allow users to change it.
 */
const isDisabled = true;

interface Props {
  isInFooter?: boolean;
}
export const CurrencySelector = (props: Props) => {
  const { isInFooter } = props;

  const isMobile = useIsMobile();
  const isPublicPage = useIsPublicPage();
  const currencyMutation = useCurrencyMutation();
  const tenantCurrency = useTenantCurrency();
  const tenantCurrencySymbol = useTenantCurrencySymbol();
  const currencyList = useCurrencyList();
  const t = useTranslations('currencySelector');
  const { sentryLog } = useSentryMonitoring();

  const selectedCurrency = useMemo(() => {
    /**
     * NOTE(SonTruongAscenda):
     * Fallback to tenantCurrency for now as the Product Manager would like to read the currency from bootstrap.
     */
    return (
      currencyList.find((currency) => currency.value === tenantCurrency) ?? {
        value: tenantCurrency,
        label: '',
        symbol: tenantCurrencySymbol,
      }
    );
  }, [currencyList, tenantCurrency, tenantCurrencySymbol]);

  const handleChangeCurrency = useCallback(
    async (newCurrency: Currency) => {
      if (!isPublicPage) {
        await currencyMutation.mutateAsync(newCurrency).catch((error) => {
          sentryLog.error(
            new Error(
              `Error while updating user preferred currency - ${error.toString()}`,
              { cause: error },
            ),
          );
        });
      }
    },
    [currencyMutation, isPublicPage, sentryLog],
  );

  const trigger = useMemo(() => {
    return (
      <CurrencySelectorTrigger
        isDisabled={isDisabled}
        currency={
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          selectedCurrency as ReturnType<typeof useCurrencyList>[number]
        }
        hasDropdownIcon={!isInFooter}
      />
    );
  }, [isDisabled, isInFooter, selectedCurrency]);

  if (isMobile) {
    return (
      <DialogTrigger>
        {trigger}
        <Drawer>
          {({ close }) => {
            return (
              <>
                <DrawerHeader title={t('selectCurrency')} />
                <DrawerBody className="pb-6">
                  <ListBox
                    selectionMode="single"
                    selectedKeys={[selectedCurrency.value]}
                    className="flex flex-col"
                    onSelectionChange={(keys) => {
                      if (keys) {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const newCurrency = [...keys][0] as Currency;
                        handleChangeCurrency(newCurrency);
                        close();
                      }
                    }}
                  >
                    {currencyList.map((currency) => {
                      return (
                        <CurrencySelectorItem
                          key={currency.value}
                          currency={currency}
                        />
                      );
                    })}
                  </ListBox>
                </DrawerBody>
              </>
            );
          }}
        </Drawer>
      </DialogTrigger>
    );
  }

  return (
    <DsSelect
      onSelectionChange={(key) => {
        if (key) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          handleChangeCurrency(key as Currency);
        }
      }}
      selectedKey={selectedCurrency.value}
      listBoxClassName="p-0"
      placeholderClassName="data-[placeholder=true]:text-neutral-500"
      popOverClassName="p-0 w-auto rounded"
      triggerClassName="min-w-0 border-none p-0"
      usePopoverOnMobile
      triggerField={() => trigger}
    >
      {currencyList.map((currency) => {
        return (
          <CurrencySelectorItem key={currency.value} currency={currency} />
        );
      })}
    </DsSelect>
  );
};
