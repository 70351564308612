import { useTranslations } from 'next-intl';
import { CSSProperties, useCallback, useMemo } from 'react';
import { DialogTrigger, ListBox } from 'react-aria-components';

import Drawer, {
  DrawerBody,
  DrawerHeader,
} from '@/design-system-components/drawer/drawer';
import DsSelect from '@/design-system-components/ds-select/select';
import { useLocaleConfig } from '@/i18n/hooks/use-locale-config/use-locale-config';
import { usePathname, useRouter, useSearchParams } from '@/i18n/navigation';
import { Locale } from '@/i18n/types';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';

import { LocaleSelectorItem } from './locale-selector-item';
import { LocaleSelectorTrigger } from './locale-selector-trigger';

interface LocaleSelectorProps {
  isInFooter?: boolean;
}
export const LocaleSelector = (props: LocaleSelectorProps) => {
  const { isInFooter } = props;

  const isMobile = useIsMobile();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const t = useTranslations('localeSelector');
  const { locale, locales } = useLocaleConfig();

  const isDisabled = locales.length <= 1;

  const handleChangeLocale = useCallback(
    (newLocale: Locale) => {
      /**
       * NOTE(sontruong):
       * We should use router.replace() here instead of router.push() when a user changes his locale.
       * router.replace() will REPLACE the current route in the browser history and prevent the user from going back to the old locale.
       * e.g. If he goes to a new page and then goes back to the previous page, he should see the previous page with new locale.
       * Reference: https://nextjs.org/docs/pages/api-reference/functions/use-router#routerreplace
       */
      router?.replace(
        /** FIXME(rongsen/sontruong):
         * @ts-expect-error -- TypeScript will validate that only known `params`
         * are used in combination with a given `pathname`. Since the two will
         * always match for the current route, we can skip runtime checks.
         * { pathname, params, search: searchParams.toString() },
         */
        `${pathname}${
          searchParams.toString() ? `?${searchParams.toString()}` : ''
        }`,
        {
          locale: newLocale,
        },
      );
    },
    [pathname, router, searchParams],
  );

  const trigger = useMemo(() => {
    return (
      <LocaleSelectorTrigger
        isDisabled={isDisabled}
        style={
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          {
            '--locale-selector-trigger-label-max-width': '105px',
          } as CSSProperties
        }
        locale={locale}
        labelClassName={cn({
          'max-w-[var(--locale-selector-trigger-label-max-width)] overflow-hidden text-ellipsis whitespace-nowrap':
            !isInFooter,
        })}
        hasDropdownIcon={!isInFooter}
      />
    );
  }, [isDisabled, isInFooter, locale]);

  if (isMobile) {
    return (
      <DialogTrigger>
        {trigger}
        <Drawer>
          {({ close }) => {
            return (
              <>
                <DrawerHeader title={t('selectLanguage')} />
                <DrawerBody className="pb-6">
                  <ListBox
                    selectionMode="single"
                    selectedKeys={[locale]}
                    className="flex flex-col"
                    onSelectionChange={(keys) => {
                      if (keys) {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const newLocale = [...keys][0] as Locale;
                        handleChangeLocale(newLocale);
                        close();
                      }
                    }}
                  >
                    {locales.map((locale) => {
                      return (
                        <LocaleSelectorItem key={locale} locale={locale} />
                      );
                    })}
                  </ListBox>
                </DrawerBody>
              </>
            );
          }}
        </Drawer>
      </DialogTrigger>
    );
  }

  return (
    <DsSelect
      onSelectionChange={(key) => {
        if (key) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          handleChangeLocale(key as Locale);
        }
      }}
      selectedKey={locale}
      listBoxClassName="p-0"
      placeholderClassName="data-[placeholder=true]:text-neutral-500"
      popOverClassName="p-0 w-auto rounded"
      triggerClassName="min-w-0 border-none p-0"
      usePopoverOnMobile
      triggerField={() => trigger}
    >
      {locales.map((locale) => {
        return <LocaleSelectorItem key={locale} locale={locale} />;
      })}
    </DsSelect>
  );
};
