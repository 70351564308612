import { ComponentProps, CSSProperties } from 'react';
import { ListBoxItem } from 'react-aria-components';

import { localeLabels } from '@/i18n/constants';
import { Locale } from '@/i18n/types';
import { cn } from '@/utils/tailwind';

import { LocaleSelectorPlaceholder } from './locale-selector-placeholder';

interface Props extends ComponentProps<typeof ListBoxItem> {
  locale: Locale;
  labelClassName?: string;
}
export const LocaleSelectorItem = (props: Props) => {
  const { locale, labelClassName, ...restProps } = props;

  return (
    <ListBoxItem
      id={locale}
      style={
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          '--locale-selector-item-padding-y': '10px',
          '--locale-selector-item-transition': 'background',
        } as CSSProperties
      }
      className={cn(
        'flex items-center gap-x-3 px-4 py-[var(--locale-selector-item-padding-y)] outline-none',
        'hover:cursor-pointer hover:bg-primary-200',
        'focus:bg-primary-200 focus:outline-none',
        'transition-[var(--locale-selector-item-transition)]',
        'data-[selected=true]:font-bold',
      )}
      aria-label={localeLabels[locale]}
      {...restProps}
    >
      <LocaleSelectorPlaceholder
        locale={locale}
        labelClassName={labelClassName}
      />
    </ListBoxItem>
  );
};
