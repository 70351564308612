'use client';

import { ReactNode, createContext, useState } from 'react';

export const RedirectModalContext = createContext({
  showRedirectModal: false,
  setShowRedirectModal: (show: boolean) => {},
});

export default function RedirectModalProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  return (
    <RedirectModalContext.Provider
      value={{
        showRedirectModal,
        setShowRedirectModal,
      }}
    >
      {children}
    </RedirectModalContext.Provider>
  );
}
