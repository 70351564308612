import { guardhouseErrorCodes } from '@/auth/auth-type';
import { useSearchParams, useSelectedLayoutSegments } from '@/i18n/navigation';

/**
 *
 * @returns true if the current page is the failure page e.g. /failure
 */
export function useIsFailurePage() {
  const selectedLayoutSegments = useSelectedLayoutSegments();
  return (
    selectedLayoutSegments.length === 1 &&
    selectedLayoutSegments[0] === 'failure'
  );
}

export function useIsPublicPage() {
  const selectedLayoutSegments = useSelectedLayoutSegments();

  const firstSegment = selectedLayoutSegments[0];
  let result = false;

  result = firstSegment === 'failure' || firstSegment === 'validate-config';

  if (
    // thank you noUncheckedIndexedAccess
    firstSegment &&
    firstSegment.includes('help-center') &&
    selectedLayoutSegments.at(-1) !== 'contact-us'
  ) {
    result = true;
  }

  return result;
}
/**
 *
 * @returns true if the current page is the failure page e.g. /failure
 */
export function useIsSessionExpired() {
  const isFailurePage = useIsFailurePage();
  const searchParams = useSearchParams();
  return isFailurePage
    ? searchParams.get('code') === guardhouseErrorCodes.SESSION_EXPIRED_REDIRECT
    : false;
}
