import { useQuery } from '@tanstack/react-query';

import { useHermes } from '@/auth/use-hermes';
import { queryKeys } from '@/queries/query-factory';
import { pointsAccountResponseSchema } from '@/schema/points-account.schema';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { transformApi } from '@/utils/transform-api';
import { WontFix } from '../../../global';

import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import { useSentryMonitoring } from '../monitoring/use-sentry-monitoring';

export function usePointsAccounts() {
  const baseUrl = getClientUrl();
  const path = `${baseUrl}/api/points_accounts?active=true`;
  const authedFetch = useAuthFetchKy;
  const hermes = useHermes();
  const { sentryLog } = useSentryMonitoring();

  return useQuery({
    ...queryKeys.pointsAccounts.userId,
    enabled: hermes.loggedIn,
    queryFn: async () => {
      const data = await authedFetch.get(path).json();

      const pointsAccountResponse = validateSchema({
        data: transformApi(data as WontFix),
        schemaName: 'points-accounts-response',
        zodSchema: pointsAccountResponseSchema,
        hideError: true,
      });

      const selectedPointsAccount = pointsAccountResponse.data.find(
        // TODO: condition check for same `account.id === hermes.pointsAccountId
        // https://kaligo.slack.com/archives/C014EPST1U5/p1698134404071639?thread_ts=1698033645.881699&cid=C014EPST1U5
        (account) =>
          account.status === 'active' && account.id === hermes.pointsAccountId,
      );

      if (!selectedPointsAccount) {
        sentryLog.error(
          `[usePointsAccounts] Active Points Account Id not found \n [GH] PointsAccountId: ${hermes.pointsAccountId}`,
        );
        return null;
      }

      return selectedPointsAccount;
    },
  });
}
