import { Todo } from 'global';

import { PaginationMeta } from '@/schema/shared-schema';

interface TransformOptions {
  // not sure why points activity data has some id that is not found in the included object
  ignoreNotFoundId?: boolean;
}

export function transformApi<MetaType extends {} | PaginationMeta>(
  apiData: CommonApiStructure<MetaType>,
  options?: TransformOptions,
) {
  let includedMap: GenericDataItemMap = {};

  apiData.included?.forEach((item) => {
    includedMap[item.id] = item;
  });

  // flatten the nested included data to avoid additional loop later
  const transformedIncluded = apiData.included
    ? transformApiNested(apiData.included, includedMap, options)
    : [];

  // create a map for faster lookup
  includedMap = {};
  transformedIncluded.forEach((item: Todo) => {
    includedMap[item.id] = item;
  });

  const transformedData = transformApiNested(
    apiData.data,
    includedMap,
    options,
  );

  if (apiData.meta) {
    return {
      data: transformedData,
      meta: apiData.meta,
    };
  }

  return transformedData;
}

export function transformApiNested(
  rawData: CommonApiStructure['data'],
  included: GenericDataItemMap,
  options?: TransformOptions,
): Todo {
  // array
  if (Array.isArray(rawData)) {
    return rawData.map((item) => {
      return transformApiNested(item, included, options);
    });
  }
  // object
  const result = { ...rawData };
  // TODO: remove this after the api is fixed
  // rawData.type !== 'notification'
  // temporary fix for the circular reference between notification and points_transfer_order_item
  if (rawData.relationships && rawData.type !== 'notification') {
    // transform relationships
    result.relationships = Object.fromEntries(
      Object.entries(rawData.relationships).map(([key, relationships]) => {
        // preserve null/undefined
        if (relationships.data === null || relationships.data === undefined) {
          return [key, relationships.data];
        }

        if (Array.isArray(relationships.data)) {
          if (!relationships.data.length) {
            return [key, []];
          } else {
            return [
              key,
              relationships.data.map((relationship) => {
                return resolveRelationship(relationship, included, options);
              }),
            ];
          }
        } else {
          return [
            key,
            resolveRelationship(relationships.data, included, options),
          ];
        }
      }),
    );
  }
  // flatten attributes
  // return unknown to force consumer set the correct type
  return flattenData(result);
}

function flattenData<TData extends GenericDataItem | GenericDataItem[] | null>(
  input: TData,
): unknown {
  // return null early since typeof null === 'object' :<
  if (input === null) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map((item) => {
      return flattenData(item);
    });
  }

  if (typeof input === 'object') {
    // flatten attributes and relationships
    const { attributes, relationships, ...rest } = input;
    const flatten1Level = {
      ...attributes,
      ...relationships,
      ...rest,
    };

    // nested flatten
    return Object.fromEntries(
      Object.entries(flatten1Level).map(([key, value]) => {
        // TODO: temporary disable eslint for this line
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return [key, flattenData(value as TData)];
      }),
    );
  }

  // other data types
  return input;
}

function resolveRelationship(
  relationship: RelationshipData,
  included: GenericDataItemMap,
  options?: TransformOptions,
) {
  if (relationship) {
    const id = relationship.id;
    const relatedData = included[id];

    if (relatedData) {
      if (relatedData.relationships) {
        return transformApiNested(relatedData, included, options);
      } else {
        return relatedData;
      }
    } else {
      if (options?.ignoreNotFoundId) {
        return;
      }
      console.error('Cannot find related data with id', id);
      // TODO: report error ???
    }
  }
}

type RelationshipData = { id: string; type: string } | null;
type GenericDataItem = {
  id: string;
  type: string;
  attributes: {};
  relationships?: {
    [key: string]: { data: RelationshipData | Array<RelationshipData> };
  };
};
type GenericData = GenericDataItem | Array<GenericDataItem>;

export interface CommonApiStructure<
  MetaType extends {} | undefined = undefined,
> {
  data: GenericData;
  // some apis does not have the "included" field
  // like points summary
  included?: Array<GenericDataItem>;
  meta?: MetaType;
}

type GenericDataItemMap = Record<string, GenericDataItem>;
