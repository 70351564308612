import { useTenantId } from '@/store/store';

const robinhoodIds = new Set([
  'robinhoodRcUs',
  'qa-robinhoodRcUs',
  'qarobinstg',
  'qarobinuat',
]);
/**
 * @deprecated
 * @description this is a temporary solution, please do not use this hook in new code. Please add new config to UI settings instead
 *
 */
export default function useIsRobinhood() {
  const tenantId = useTenantId();
  return tenantId ? isRobinhoodTenant(tenantId) : false;
}

export function isRobinhoodTenant(tenantId: string) {
  return robinhoodIds.has(tenantId);
}
