'use client';
import { useHermes } from '@/auth/use-hermes';
import useIsRobinhood from '@/hooks/config/tenants/use-is-robinhood';
import useComponentConfig from '@/hooks/config/use-component-config';
import { useSentryMonitoring } from '@/hooks/monitoring/use-sentry-monitoring';
import { cn } from '@/utils/tailwind';
import dynamic from 'next/dynamic';
import { useEffect, useRef } from 'react';
import { spinnerIconDisplayName } from './constants';
import { DefaultSpinnerIcon } from './default-spinner-icon';
import { SpinnerImage } from './spinner-image';
import type { SpinnerImageProps } from './types';

// lazy load RobinhoodLoadingSpinner since it's only used in Robinhood and avoid including the lottie-web library in the main bundle
const RobinhoodLoadingSpinner = dynamic(
  () =>
    import('@/components/robinhood-loading-spinner').then((mod) => {
      return mod.RobinhoodLoadingSpinner;
    }),
  { ssr: false },
);

export function SpinnerIcon({ className }: SpinnerImageProps) {
  const { loadingImage } = useComponentConfig('spinner') ?? {};
  const { sentryLog } = useSentryMonitoring();
  const { authState } = useHermes();
  const isRobinhood = useIsRobinhood();
  const shouldReportError = useRef(true);

  let content = <DefaultSpinnerIcon />;

  if (loadingImage?.src) {
    content = <SpinnerImage {...loadingImage} />;
  }

  if (isRobinhood) {
    content = <RobinhoodLoadingSpinner />;
  }

  useEffect(() => {
    // ensure we only report the error once
    if (!shouldReportError.current) {
      return;
    }
    const timeoutId = setTimeout(() => {
      sentryLog.fatal(
        `SpinnerIcon is displayed more than 10 seconds ${JSON.stringify({
          data: authState,
        })}`,
      );

      shouldReportError.current = false;
    }, 15_000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [authState, sentryLog]);

  return (
    <div
      className={cn(
        'fixed inset-x-0 inset-y-0 z-[99999] flex flex-grow items-center justify-center bg-neutral-200',
        className,
      )}
    >
      {content}
    </div>
  );
}

SpinnerIcon.displayName = spinnerIconDisplayName;
