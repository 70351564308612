import { z } from 'zod';

const pointsAccountTrancheSchema = z.object({
  expiryDate: z.string(),
  balance: z.number(),
  hasExpirationDate: z.boolean(),
});

export const pointsAccountSchema = z.object({
  id: z.string(),
  pointsBalance: z.number(),
  status: z.enum(['active', 'closing']),
  tranches: z.array(pointsAccountTrancheSchema),
});

export const pointsAccountResponseSchema = z.object({
  data: z.array(
    pointsAccountSchema.extend({
      id: z.string(),
      type: z.enum(['points_account']),
    }),
  ),
  meta: z.object({
    count: z.number(),
  }),
});

export type Tranche = z.infer<typeof pointsAccountTrancheSchema>;
export type PointsAccount = z.infer<typeof pointsAccountSchema>;
export type PointsAccountResponse = z.infer<typeof pointsAccountResponseSchema>;
