import { Icon } from '@/components/icon';
import { defaultSpinnerIconDisplayName } from './constants';

export function DefaultSpinnerIcon() {
  return (
    <Icon
      className="fa-spin h-16 w-16 fill-primary [--fa-animation-duration:700ms]"
      name="circle-notch"
      role="graphics-symbol"
    />
  );
}

DefaultSpinnerIcon.displayName = defaultSpinnerIconDisplayName;
