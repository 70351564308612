import { Icon } from '@/components/icon';
import { useCurrencyList } from '@/hooks/currency-selector/use-currency-list';

interface Props {
  currency: ReturnType<typeof useCurrencyList>[number];
  hasDropdownIcon?: boolean;
}
export const CurrencySelectorPlaceholder = (props: Props) => {
  const { currency, hasDropdownIcon } = props;

  return (
    <>
      {/**
       * NOTE(SonTruongAscenda):
       * <strong>{currency.symbol}</strong>
       * We don't use the currency symbol until we support multiple currencies per tenant.
       */}
      <span className="text-sm-normal lg:text-base-normal">
        {currency.value}
      </span>
      {hasDropdownIcon ? (
        <Icon name="chevron-down" className="ml-1 h-4 w-4 text-neutral-500" />
      ) : null}
    </>
  );
};
