import useLoyaltyPrograms from './use-loyalty-programs';

export function useUberCredit({ enabled = true }: { enabled: boolean }) {
  const { loyaltyPrograms } = useLoyaltyPrograms({
    loyaltyProgramType: 'voucher',
    sortBy: 'default',
    enabled,
  });
  const uberLoyaltyProgram = loyaltyPrograms?.data?.[0];

  return { uberLoyaltyProgramId: uberLoyaltyProgram?.id, uberLoyaltyProgram };
}
