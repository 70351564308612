import { ComponentProps, CSSProperties } from 'react';
import { Button } from 'react-aria-components';

import { Locale } from '@/i18n/types';
import { cn } from '@/utils/tailwind';

import { LocaleSelectorPlaceholder } from './locale-selector-placeholder';

interface Props extends ComponentProps<typeof Button> {
  locale: Locale;
  labelClassName?: string;
  hasDropdownIcon?: boolean;
}
export const LocaleSelectorTrigger = (props: Props) => {
  const { isDisabled, locale, labelClassName, hasDropdownIcon, ...restProps } =
    props;

  return (
    <Button
      data-testid="locale-selector-trigger"
      isDisabled={isDisabled}
      style={
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          '--transition': 'background',
        } as CSSProperties
      }
      className={cn(
        'flex w-fit items-center gap-x-2 rounded p-1 outline-none',
        'transition-[var(--transition)] hover:bg-neutral-900 hover:bg-opacity-5',
        'data-[pressed=true]:bg-neutral-900 data-[pressed=true]:bg-opacity-10',
        'data-[disabled=true]:!bg-transparent data-[disabled=true]:opacity-50',
        'data-[focus-visible=true]:shadow-focus',
      )}
      {...restProps}
    >
      <LocaleSelectorPlaceholder
        locale={locale}
        labelClassName={labelClassName}
        hasDropdownIcon={hasDropdownIcon}
      />
    </Button>
  );
};
