import useIsRobinhood from '@/hooks/config/tenants/use-is-robinhood';
import { useFeatureConfig } from '@/hooks/config/use-feature-config';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';

export function useFlightsFlag() {
  const isRobinhood = useIsRobinhood();
  const travelConfig = useFeatureConfig('travel');
  const uiSettingFlag = travelConfig?.features?.flights?.enabled;
  const unleashFlag = useFlagsReady('robinhood_uat_flights');

  // TODO: support prod flag later
  if (isRobinhood) {
    return uiSettingFlag && unleashFlag;
  }
  return uiSettingFlag;
}
